import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private _http: HttpClient) {}

  login(): Observable<any> {
    const body = {
      'username': 'zied.benslimen@etudiant-isi.utm.tn',
      'password': 'algaepool123',
    };
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this._http.post('https://thingsboard.cloud/api/auth/login', body, {headers});
  }
  loadToken() {
    this.login().subscribe(
        currentUser => {
          localStorage.setItem('token', currentUser.token);
          localStorage.setItem('currentUser', JSON.stringify(currentUser));
          localStorage.setItem('auth-refreshtoken', currentUser.refreshToken);

        });
  }
}
